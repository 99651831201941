

































































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    commissionId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    ratingScore: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      done: false,
      error: null as Error | null,
      loading: false,
      score: null as number | null,
    };
  },
  watch: {
    error: {
      immediate: false,
      handler(e) {
        if (!e) return;

        this.$toast({
          message: e.message,
          classes: 'is-danger',
          position: 'top',
        });
      },
    },
    score: {
      immediate: false,
      handler(score: number | null) {
        if (score !== null && score >= -5 && score <= 5) this.submit();
      },
    },
  },
  methods: {
    async submit(): Promise<void> {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/commission/RateUser', {
        BUSINESS_ID: this.businessId,
        COMMISSION_ID: this.commissionId,
        form: {
          score: this.score,
        },
      }).then(() => { this.done = true; })
        .catch((e) => { this.error = e; });

      this.loading = false;
    },
    getComponentNameForScore(score: number | null): string {
      switch (score) {
        case -2:
          return 'SvgEmoteFrown';
        case 0:
          return 'SvgEmoteMeh';
        case +2:
          return 'SvgEmoteSmile';

        default:
          return 'SvgX';
      }
    },
  },
});
