



























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import AcceptForm from '@/components/commission/AcceptForm.vue';
import TouchForm from '@/components/commission/TouchForm.vue';
import UserRatingForm from '@/components/commission/UserRatingForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission | undefined,
  findCustomer: (u: string) => ApiResource.User | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AcceptForm,
    TouchForm,
    UserRatingForm,
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      findCustomer: 'business/commission/FindCustomer',
      findRating: 'business/commission/FindRating',
      listEvents: 'business/commission/event/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission | undefined {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    customer(): ApiResource.User | undefined {
      if (!this.commission) return undefined;
      return this.findCustomer(this.commission.customer_id);
    },
    rating(): ApiResource.UserRating | undefined {
      if (!this.commission) return undefined;
      return this.findRating(this.commission.customer_id);
    },
    updatedWithin14Days(): boolean {
      if (!this.commission) return false;
      const updatedAt = new Date(this.commission.updated_at * 1000);

      updatedAt.setUTCDate(updatedAt.getUTCDate() + 14);

      return updatedAt >= new Date();
    },
  },
  watch: {
    '$route.params.COMMISSION_ID': {
      immediate: true,
      async handler(COMMISSION_ID) {
        if (!this.commission) {
          await this.$store.dispatch('business/commission/Find', {
            BUSINESS_ID: this.business.id,
            COMMISSION_ID,
          });
        }

        this.fetchMessages();
        this.fetchEvents();
        this.fetchStats();
        this.fetchItems();
        this.fetchTrackers();
      },
    },
    'commission.customer_id': {
      immediate: true,
      handler(to, from) {
        if (to && to !== from) this.fetchCustomer();
      },
    },
    'commission.status': {
      immediate: true,
      handler(status) {
        if (status === 'finished') this.fetchRating();
      },
    },
  },
  methods: {
    async fetchCustomer() {
      await this.$store.dispatch('business/commission/GetCustomer', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
      });
    },
    async fetchEvents(limit = 100, offset = 0) {
      await this.$store.dispatch('business/commission/event/List', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
        query: {
          limit,
          offset,
          // Oldest first
          order: 'id',
          direction: 'asc',
        },
      });

      if (this.listEvents(this.COMMISSION_ID).length === limit + offset) {
        this.fetchEvents(limit, limit + offset);
      }
    },
    async fetchMessages() {
      await this.$store.dispatch('business/commission/messages/List', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
        query: {
          limit: 20,
        },
      });
    },
    async fetchStats() {
      await this.$store.dispatch('business/commission/Stats', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
      });
    },
    async fetchItems() {
      await this.$store.dispatch('business/commission/item/List', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
        query: {
          limit: 20,
        },
      });
    },
    async fetchTrackers() {
      await this.$store.dispatch('business/commission/trackers/List', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
      });
    },
    async fetchRating() {
      await this.$store.dispatch('business/commission/GetRating', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.COMMISSION_ID,
      });
    },
  },
});
