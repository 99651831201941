

















































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import CommissionProgressSteps from '@/components/commission/ProgressSteps.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
  getStats: (c: string) => ApiResource.CommissionStats | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AdvancedDetailsTable: () => import(/* webpackChunkName: "c-adt" */ '@/components/commission/AdvancedDetailsTable.vue'),
    BasicDetailsTable: () => import(/* webpackChunkName: "c-bdt" */ '@/components/commission/BasicDetailsTable.vue'),
    CommissionProgressSteps,
    FileUsage: () => import(/* webpackChunkName: "c-f-u" */ '@/components/commission/FileUsage.vue'),
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      getStats: 'business/commission/Stats',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.getStats(this.COMMISSION_ID);
    },
  },
});
