

























































































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    business: {
      required: true,
      type: Object as () => ApiResource.Business,
      validator(obj: any): obj is ApiResource.Business {
        return obj.object === 'business';
      },
    },
    commission: {
      required: true,
      type: Object as () => ApiResource.Commission,
      validator(obj: any): obj is ApiResource.Commission {
        return obj.object === 'commission';
      },
    },
  },
  data() {
    return {
      error: null,
      form: null,
      loading: false,
      show: false,
      validation: null,
    };
  },
  computed: {
    options() {
      const res: any[] = [];

      const REJECT_REASONS = [
        'budget',
        'busy',
        'other',
        'scope',
      ];

      const REPORT_REASONS = [
        'terms',
        'spam',
      ];

      REJECT_REASONS.concat(REPORT_REASONS).forEach((reason) => {
        res.push({
          value: reason,
          text: this.$t(`reason.${reason}`),
        });
      });

      return res;
    },
  },
  methods: {
    async accept() {
      this.loading = true;

      await this.$store.dispatch('business/commission/Accept', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('accepted', true);
      this.$emit('request-accepted', this.commission.id);

      this.$a.goal(this.$a.goals.CommissionRequestAccept);
    },
    async reject() {
      this.loading = true;

      await this.$store.dispatch('business/commission/Reject', {
        BUSINESS_ID: this.business.id,
        COMMISSION_ID: this.commission.id,
        form: {
          reason: this.form,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('rejected', true);
    },
  },
});
